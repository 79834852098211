export function getListItems(callback) {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/api/items/`;
  fetch(url)
    .then((response) => response.json())
    .then((data) => callback(data));
}

export function sendNewListItem(newListItem, callback) {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/api/items/`;
  fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(newListItem),
  })
    .then((response) => response.json())
    .then((data) => callback(data));
}

export function updateListItem(updatedListItem, callback) {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/api/items/${updatedListItem.id}/`;
  fetch(url, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(updatedListItem),
  })
    .then((response) => response.json())
    .then((data) => callback(data));
}

export function deleteListItem(listItemToDelete, callback) {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/api/items/${listItemToDelete.id}/`;
  fetch(url, {
    method: "DELETE",
  })
    .then((response) => response.json())
    .then((data) => callback(data));
}
