import React, { useEffect } from "react";
import "./App.css";
import { Button, Card, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getListItems,
  sendNewListItem,
  updateListItem,
  deleteListItem,
} from "./services";
import dayjs from "dayjs";
import Reward from "react-rewards";

function ListItem({ listItem, index, markListItem, removeListItem }) {
  const createdDateStr = dayjs(listItem.datetime_created).format("MM/DD/YY");
  const completedDateStr = listItem.datetime_completed
    ? dayjs(listItem.datetime_completed).format("MM/DD/YY")
    : null;
  let reward;

  return (
    <div className="list-item">
      <span style={{ textDecoration: listItem.is_done ? "line-through" : "" }}>
        {listItem.title}
      </span>

      <div className="list-item-right">
        <span className="date">
          Created: {createdDateStr}
          {completedDateStr && `, Finished ${completedDateStr}`}
        </span>
        <div className="list-item-button-section">
          <Reward
            ref={(ref) => {
              reward = ref;
            }}
            type="confetti"
          >
            <Button
              variant="outline-success"
              onClick={() => {
                if (!listItem.is_done) {
                  reward.rewardMe();
                }
                markListItem(index);
              }}
            >
              ✓
            </Button>
          </Reward>{" "}
          <Button
            variant="outline-danger"
            onClick={() => removeListItem(index)}
          >
            ✕
          </Button>
        </div>
      </div>
    </div>
  );
}

function FormListItem({ addListItem }) {
  const [value, setValue] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!value) return;
    addListItem(value);
    setValue("");
  };

  return (
    <Form className="input-form" onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Control
          type="text"
          className="input"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Add something new to do together 😊"
        />
      </Form.Group>
      <Button variant="secondary mb-3" type="submit">
        Submit
      </Button>
    </Form>
  );
}

function App() {
  const [listItems, setListItems] = React.useState([]);

  /**
   * Function to add a list item to the front and back end
   * @param {string} title - title of the todo
   */
  const addListItem = (title) => {
    const newListItem = { title: title };
    sendNewListItem(newListItem, (data) => {
      const newListItems = [...listItems, data];
      setListItems(sortListItems(newListItems));
    });
  };

  /**
   * Checks off a list item on the front and back end
   * @param {number} index - Index of the list item to check
   */
  const markListItem = (index) => {
    const newListItems = [...listItems];
    if (!newListItems[index].is_done) {
      newListItems[index].is_done = true;
    } else {
      newListItems[index].is_done = false;
      newListItems[index].datetime_completed = null;
    }
    const updatedListItem = newListItems[index];
    updateListItem(updatedListItem, (data) => {
      newListItems[index] = data;
      const updatedListItems = [...newListItems];
      setListItems(sortListItems(updatedListItems));
    });
  };

  /**
   * Deletes a list item on the front and back end
   * @param {number} index - Index of the list item to delete
   */
  const removeListItem = (index) => {
    const newListItems = [...listItems];
    const itemToDelete = newListItems[index];
    newListItems.splice(index, 1);
    deleteListItem(itemToDelete, (data) => {
      setListItems(sortListItems(newListItems));
    });
  };

  /**
   * Sort list items and set it on the effect
   */
  const sortListItems = (items) => {
    const sortedListItems = [...items].sort((a, b) => {
      if (a.is_done && !b.is_done) {
        return 1000000000000;
      } else if (b.is_done && !a.is_done) {
        return -1000000000000;
      } else if (a.is_done && b.is_done) {
        return new Date(b.datetime_completed) - new Date(a.datetime_completed);
      }
      return new Date(b.datetime_created) - new Date(a.datetime_created);
    });
    return sortedListItems;
  };

  // Get the list items on init
  useEffect(() => {
    getListItems((data) => {
      setListItems(sortListItems(data));
    });
  }, []);

  return (
    <div className="app">
      <div className="container">
        <h1 className="list-heading text-center mb-4">
          💘 Jessy and Brody's List 💘
        </h1>
        <FormListItem addListItem={addListItem} />
        <div>
          {listItems.map((listItem, index) => (
            <Card key={index}>
              <Card.Body
                key={index}
                className={
                  listItem.is_done
                    ? "list-item-container list-item--completed"
                    : "list-item-container"
                }
              >
                <ListItem
                  key={index}
                  index={index}
                  listItem={listItem}
                  markListItem={markListItem}
                  removeListItem={removeListItem}
                />
              </Card.Body>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
